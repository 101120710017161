import { Check, ExternalLink } from 'react-feather';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { CookieConsentContext } from './CookieConsentProvider';
import UI from '../UI';
import useLocale from '../useLocale';

const Backdrop = styled.div`
  ${({ theme }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, .3);
    backdrop-filter: blur(3px);
    z-index: 1000;
    padding ${theme.gutter}px;
  `}
`;

const Container = styled(UI.Card)`
  max-width: 450px;
  margin: 20vh auto;
  text-align: center;

  ${UI.H3} {
    font-size: 1.2em;
  }
`;

const CookieConsentBar = () => {
  const { t } = useTranslation('common');
  const { cookieConsent, acceptCookies, denyCookies } = useContext(CookieConsentContext);
  const { locale } = useLocale();

  const containerRef = useRef<HTMLDivElement>();

  if (cookieConsent !== null) {
    return <></>;
  }

  return (
    <Backdrop>
      <Container ref={containerRef}>
        <UI.H3 sc={{ mb: 2 }}>
          {t('cookie_consent.cookies')}
        </UI.H3>
        <UI.Paragraph sc={{ mb: 1 }}>
          {t('cookie_consent.cookie_consent_warning')}
        </UI.Paragraph>
        <UI.Paragraph sc={{ mb: 4 }}>
          <UI.A
            href={`/${locale}/privacy`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ whiteSpace: 'nowrap' }}
            sc={{ mt: [1, 0] }}
          >
            {t('cookie_consent.read_cookie_policy')}
            {' '}
            <UI.Icon>
              <ExternalLink />
            </UI.Icon>
          </UI.A>
        </UI.Paragraph>
        <UI.Paragraph>
          <UI.Button
            onClick={() => denyCookies()}
            sc={{ outline: true, color: 'gray.500', mr: 1 }}
            style={{ fontWeight: 300 }}
          >
            {t('cookie_consent.disable')}
          </UI.Button>
          {' '}
          <UI.Button
            onClick={() => acceptCookies()}
            sc={{ brand: 'primary.600' }}
          >
            <UI.Icon>
              <Check />
            </UI.Icon>
            {' '}
            <UI.Strong>{t('cookie_consent.ok_continue')}</UI.Strong>
          </UI.Button>
        </UI.Paragraph>
      </Container>
    </Backdrop>
  );
};

export default CookieConsentBar;
